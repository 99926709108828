<div class="app-container rolled-out">
  <ix-button [ghost]="true" icon="arrow-left" variant="primary" [routerLink]="['/about-legal-info']" class="rolled-out__return">
    {{ "IPP_NAVIGATION.ABOUT_LEGAL_INFO.ROLLED_OUT.BACK_TO_MAIN_PAGE" | translate }}
  </ix-button>

  <ix-content-header
    variant="Primary"
    headerTitle="{{ 'IPP_NAVIGATION.ABOUT_LEGAL_INFO.ROLLED_OUT.TITLE' | translate }}"
    headerSubtitle="{{ 'IPP_NAVIGATION.ABOUT_LEGAL_INFO.ROLLED_OUT.SUB_TITLE' | translate }}" />
  <div class="rolled-out__container" *ngIf="countriesList$ | async as countriesList">
    <div class="rolled-out__world-map">
      <app-worldmap [countries]="countriesList" />
    </div>
    <div class="rolled-out__country-list">
      <app-rolled-out-countries-list [countriesList]="countriesList" />
    </div>
  </div>
</div>
