import { EChartsOption } from "echarts";
import { colors } from "./color-options";

export const worldMapOption: EChartsOption = {
  emphasis: {
    focus: "self",
  },
  toolbox: {
    show: true,
    feature: {
      restore: {},
    },
  },
  series: [
    {
      name: "Rolled Out Countries",
      type: "map",
      selectedMode: false,
      roam: true,
      map: "world",
      zoom: 0,
      scaleLimit: {
        min: 1,
        max: 4,
      },
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      itemStyle: {
        areaColor: colors.defaultBackground,
        color: colors.defaultText,
        borderColor: colors.borderColor,
      },
      emphasis: {
        itemStyle: {
          areaColor: colors.highlightBackground,
          color: colors.defaultText,
        },
        label: {
          show: true,
          color: colors.highlightedText,
          backgroundColor: colors.defaultBackground,
          padding: 4,
        },
      },
      data: [],
    },
  ],
};
