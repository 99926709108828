import { Component } from "@angular/core";
import { CountryService } from "../../../shared/services/country.service";

@Component({
  selector: "app-rolled-out",
  templateUrl: "./rolled-out.component.html",
  styleUrls: ["./rolled-out.component.scss"],
})
export class RolledOutComponent {
  constructor(protected countryService: CountryService) {}
  public countriesList$ = this.countryService.getRolledOutCountries();
}
