import { Injectable } from "@angular/core";
import { colors } from "../../../legal-documents/components/worldmap/config/color-options";
import { CountryDTOResponse, CountryDto } from "../../models";

@Injectable({
  providedIn: "root",
})
export class CountryAdapterService {
  public adapt(countryDTOResponse: CountryDTOResponse): CountryDto[] {
    const { countries } = countryDTOResponse;
    return countries.map((country) => addItemStyle(country));
  }
}

const addItemStyle = (country: CountryDto): CountryDto => {
  return {
    name: country.name,
    isoCode: country.isoCode,
    status: country.status,
    itemStyle: {
      areaColor: country.status === "Available" ? colors.rolledOutBackground : colors.comingSoonBackground,
      color: colors.defaultText,
    },
  };
};
