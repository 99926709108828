import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CountryDto, CountryDTOResponse } from "../../../app/shared/models/country-dto.model";
import { map, Observable } from "rxjs";
import { CountryAdapterService } from "./adapters/country-adapter.service";

@Injectable({
  providedIn: "any",
})
export class CountryService {
  private static readonly baseUrl: string = `${environment.target.gatewayUrl.toString()}premium-portal/countries`;

  constructor(protected http: HttpClient, protected countryAdapterService: CountryAdapterService) {}

  /**
   * @param url World map definitions in geojson
   * @returns
   */
  public getGeoJsonData(url: string): Observable<object> {
    return this.http.get(url);
  }

  public getRolledOutCountries(): Observable<CountryDto[]> {
    return this.http
      .get<CountryDTOResponse>(`${CountryService.baseUrl}?status=Available`)
      .pipe(map((countries: CountryDTOResponse) => this.countryAdapterService.adapt(countries)));
  }
}
