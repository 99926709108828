import { Component, Input } from "@angular/core";

import { registerTheme } from "@siemens/ix-echarts";
import * as echarts from "echarts";
import { SubscriberBase } from "../../../core/abstract/subscriber-base";
import { CountryDto } from "../../../shared/models";
import { CountryService } from "../../../shared/services/country.service";
import { worldMapOption } from "./config/map-options";

registerTheme(echarts);

@Component({
  selector: "app-worldmap",
  standalone: false,
  templateUrl: "./worldmap.component.html",
  styleUrls: ["./worldmap.component.scss"],
})
export class WorldmapComponent extends SubscriberBase {
  private chartData!: echarts.ECharts;
  public countriesList: CountryDto[];

  @Input()
  public set countries(countries: CountryDto[]) {
    this.countriesList = countries;

    this.initDom();
    this.setupWorldMap();
  }

  constructor(protected countryService: CountryService) {
    super();
  }

  private initDom(): void {
    const dom = document.getElementById("container");
    echarts.dispose(dom);
    const chartData = echarts.init(dom as HTMLCanvasElement, {
      renderer: "canvas",
    });

    window.addEventListener("resize", function () {
      chartData.resize();
    });

    this.chartData = chartData;
  }

  /**
   * These geoJson data comes from https://geojson-maps.ash.ms/
   *
   */
  private setupWorldMap(): void {
    this.chartData.showLoading();
    this.addSub(
      this.countryService.getGeoJsonData("../assets/data/world.json").subscribe((countries) => {
        this.chartData.hideLoading();
        echarts.registerMap("world", countries as never);
        // TODO: Remove on release 4.4
        // rename Taiwan, republic of to Taiwan
        this.countriesList.forEach((c) => {
          if (c.name.includes("Taiwan")) {
            c.name = "Taiwan";
          }
        });
        worldMapOption.series[0].data = this.countriesList;
        this.chartData.setOption(worldMapOption);
      })
    );
  }
}
