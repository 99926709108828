import { Component, Input } from "@angular/core";
import { CountryDto } from "../../../../app/shared/models/country-dto.model";

@Component({
  selector: "app-rolled-out-countries-list",
  templateUrl: "./rolled-out-countries-list.component.html",
  styleUrls: ["./rolled-out-countries-list.component.scss"],
})
export class RolledOutCountriesListComponent {
  @Input()
  countriesList: CountryDto[] = [];
}
